import React from "react";
import Home from "./Home";

const IndexPage = () => (
  <>
    <Home />
  </>
);

export default IndexPage;
